import "bootstrap";

import "remixicon/fonts/remixicon.css";
import "../scss/main.scss";

import "./sections/locations";
import "./sections/prices";
import "./sections/providers";

import $ from "jquery";

$(() => {
    const $viewport = $("html, body");

    $(document).on("click", "[data-scroll]", function () {
        const scrollTop = $(this.dataset.scroll).offset().top;
        $viewport.stop().animate({ scrollTop }, 1000);
    });
});
