import $ from "jquery";

$(() => {
    if ($("#providers").length == 0)
        return;

    $<HTMLInputElement>("#providers .network-provider img").tooltip({
        title(this: HTMLImageElement) {
            return this.alt;
        },
    });
});
