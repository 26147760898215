import $ from "jquery";

$(() => {
    if ($("#prices").length == 0)
        return;

    $<HTMLInputElement>("#prices .category-switcher").on("change", function () {
        if (this.checked) {
            $("#prices .plans.active").removeClass("active");

            const $current = $(`#prices .plans[data-category="${this.value}"]`);
            void $current[0].offsetWidth; // Reset CSS animation state on fucking Safari
            $current.addClass("active");
        }
    });
});
